const fa={

	
	//Istikharah

	//Quran

	//khatam
	"khatam":"ختم",
	"sort":"ترتیب",


	// App bar
	"forghan": "فرقان",
	"services": "خدمات",
	"language": "انتخاب زبان",
	"menu": "فهرست",
	"closeMenu": "بستن فهرست",
	"searchAndFilter":"جستجو و فیلتر",

	// Quran Menu
	"displayOption": "تنظیمات نمایش",
	"readingMode":"حالت مطالعه",
	"nightmode": "حالت شب",
	"daymode": "حالت روز",

	// Display Option
	"appearance": "نمایش",
	"text": "متن",
	"font": "قلم",
	"lineHeight": "ارتفاع خط",
	"wordSpacing": "فاصله کلمات",
	"letterSpacing": "فاصله حروف",
	"alignment": "محل قرارگیری متن",
	"fontFamily": "نوع قلم",
	"fontSize": "اندازه قلم",
	"fontColor": "رنگ قلم",
	"textEdition":'نوع متن',
	"simpleDescription":"متن ساده (املایی)",
	"simpleEnhancedDescription":'متن ساده همراه با نمایش اخفا و ادغام',
	"simpleMinimalDescription":'متن ساده با حداقل اعراب',
	"simpleCleanDescription":"متن ساده بدون اعراب",
	"uthmaniDescription":"متن عثمانی یا مصحف مدنی",
	"uthmaniMinimalDescription":'متن عثمانی با حداقل اعراب',
	"tajwidDescription":'متن با قواعد تجوید',
	"submit": "اعمال",
	"reset": "پیش فرض",
	"tajweed": "تجوید",
	"tajweedRules":"قواعد تجوید",
	"shade":"تیرگی",
	"hamzatUlWasl":"همزه وصل",
	"silent":"Silent",
	"lamShamsiyah":"حروف شمسی",
	"maddaNormal":'مد اصلی',
	"maddaPermissible":'مد منفصل',
	"maddaNecessary":'مد سکون عارضی',
	"qalaqah":'قلقله',
	"maddaObligatory":'مد لازم',
	"ikhafaShafawi":'اخفاء شفوی',
	"ikhafa":'اخفا',
	"idghamShafawi":'ادغام شفوی',
	"iqlab":'اقلاب',
	"idghamWithGhunnah":'ادغام با غنه',
	"idghamWithoutGhunnah":'ادغام بدون غنه',
	"idghamMutajanisayn":'ادغام متجانسین',
	"idghamMutaqaribayn":'ادغام متقابلین',
	"ghunnah":'غنه',

	// Drawer Menu
	"quran": "قرآن کریم",
	"browse": "پیمایش",
	"sura":"سوره",
	"aya":"آیه",
	"page":"صفحه",
	"juz": "جزء",
	"hizb": "حزب",
	"rubElHizb":"ربع حزب",
	"ruku":"رکوع",
	"manzil": "منزیل",
	"translation":"ترجمان",
	"translator":"مترجم",
	"addTranslation":"افزودن ترجمان",
	"loop": "تکرار",
	"noloop": "بدون تکرار",
	"recitation":"تلاوت",
	"reciter":"قاری",
	"type":"نوع",
	"murattal":'ترتیل',
	"mujawwad":'تجوید',
	"Muallim":'تعلیم',
	"quality":"کیفیت",
	"highquality": 'کیفیت بالا',
	"normalquality": 'کیفیت متوسط',
	"lowquality": 'کیفیت پایین',
	"playMode": "نحوه تلاوت",
	"continious":"پیوسته",

	// Istikharah
	'ist_ceremonial': 'آداب استخاره',
	'ist_result': 'پاسخ استخاره',
	'ist_aya_display': 'نمایش آیه',
	'ist_istikharah': 'استخاره',
	'ist_repeat': 'استخاره مجدد',
	'ist_page_display': 'نمایش صفحه قرآن',
	'ist_result_display': 'نمایش پاسخ',

	// Search
	"albalaq": 'البلاغ',
	"tafseer": 'تفسیر ',
	"in": 'در',
	"advancedSearch": 'پیشرفته',
	"allOfThem":'شامل همه این کلمات',
	"exacts":'عین این عبارت',
	"oneOfThem":'شامل حداقل یکی از این کلمات',
	"noneOfThem":'بدون این کلمات',
	"search":"جستجو",

	//new
	"login":"ورود",
	"phoneNumberNo0":"شماره همراه بدون صفر ایتدایی",
	"sendCode" : "ارسال کد",
	"orLoginWith":"ورود با استفاده از:",
	"verificationDescription": "ما بمنظور صحت سنجی شماره تلفنتان یک کد برایتان ارسال میکنیم",
	"wrongPhoneNumber?" : "شماره را اشتباه وارد کرده اید?",
	"newKhatam":"ختم جدید",
	"titleOfKhatam":"عنوان ختم",
	"titleOfKhatamCaption" : "برای ختم جدید یک عنوان بنویسید",
	"selectKhatamSections":"تعداد بخشهای ختم را مشخص کنید:",
	"include":"شامل",
	"about":"حدود",
	"pages":"صفحه",
	"description":"توضیحات",
	"tags":"برچسب ها",
	"tagsCaption":"برچسب را وارد کنید و کلید اینتر را بزنید",
	"create":"ساختن",
	"cancel":"لغو",
	"doNotShowToPublic":"این ختم را به همه نمایش نده",
	"onlySignedUsers":"فقط کاربران شناخته شده در این ختم مشارکت کنند",
	"dontExtend":"پس از اتمام ختم، دوره تکرار نشود",
	"khatamCreatedMsg":"ختم ساخته شد",
	"share":"اشتراک",
	"join":"مشارکت",
	"ofCourse":"از دوره",
	"shareKhatam":"به اشتراک گذاری ختم",
	"jazakumullah":"جزاکم الله",
	"dedicatedToYou":"به شما اختصاص داده شد",
	"to" : "الی",
	"requestQuotas":"درخواست سهم",
	"readQuotas":"قرائت سهم",
	"close":"بستن",
	"contributers":"مشارکت کنندگان",
}


export default fa;