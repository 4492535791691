const ar= {

	//Global
	
	
	//Istikharah

	//Quran

	//khatam
	"khatam":"ختم",
	"sort":"sort",

	// App bar
	"forghan": "الفرقان",
	"services": "خدمات",
	"language": "لغة",
	"menu": "قائمة طعام",
	"closeMenu": "إغلاق القائمة",
	"searchAndFilter":"search And Filter",

	// Quran Menu
	"displayOption": "خيارات العرض",
	"readingMode":"وضع القراءة",
	"nightmode": "وضع الليل",
	"daymode": "وضع اليوم",

	// Display Option
	"appearance": "مظهر خارجي",
	"text": "نص",
	"font": "الخط",
	"lineHeight": "ارتفاع خط",
	"wordSpacing": "تباعد الكلمات",
	"letterSpacing": "تباعد الأحرف",
	"alignment": "المحاذاة",
	"fontFamily": "خط العائلة",
	"fontSize": "حجم الخط",
	"fontColor": "لون الخط",
	"textEdition":'الطبعة النص',
	"simpleDescription":"Simple (imla'ei) text",
	"simpleEnhancedDescription":'Simple text with ikhfa and idgham demonstration',
	"simpleMinimalDescription":'Simple text with a minimal number of diacritics and symbols',
	"simpleCleanDescription":"Simple text without any diacritics and symbols",
	"uthmaniDescription":"Uthmani text as in Medina Mushaf",
	"uthmaniMinimalDescription":'Uthmani text with a minimal number of diacritics and symbols',
	"tajwidDescription":'Text with capability of coloring tajwid rules',
	"submit": "إرسال",
	"reset": "إفتراضي",
	"tajweed": "التجويد",
	"tajweedRules":"قواعد التجويد",
	"shade":"ظل",
	"hamzatUlWasl":"همزة الوصل",
	"silent":"سكون",
	"lamShamsiyah":"اللام الشمسية",
	"maddaNormal":'المد الأصلي',
	"maddaPermissible":'المد المنفصل',
	"maddaNecessary":'المد العارض للسكون',
	"qalaqah":'القلقلة',
	"maddaObligatory":'المد اللازم',
	"ikhafaShafawi":'الإخفاء الشفوي',
	"ikhafa":'الإخفاء',
	"idghamShafawi":'الإدغام الشفوي',
	"iqlab":'إقلاب',
	"idghamWithGhunnah":'الإدغام بغنة',
	"idghamWithoutGhunnah":'الإدغام بدون غنة',
	"idghamMutajanisayn":'إدغام المتجانسين',
	"idghamMutaqaribayn":'إدغام المتقاربين',
	"ghunnah":'الغُنّة',

	// Drawer Menu
	"quran": "القرآن",
	"browse": "تصفح",
	"sura":"سورة",
	"aya":"آية",
	"page":"الصفحة",
	"juz": "جزء",
	"hizb": "حزب",
	"rubElHizb":"ربع الحزب",
	"ruku":"ركوع",
	"manzil": "منزيل",
	"translation":"ترجمة",
	"translator":"مترجم",
	"addTranslation":"أضف ترجمة",	
	"loop": "أنشوطة",
	"noloop": 'لا أنشوطة',
	"recitation":"التلاوة",
	"reciter":"القارئ",
	"type":"نوع",
	"murattal":'مرتلة',
	"mujawwad":'مجودة',
	"muallim":'المعلم',
	"quality":"جودة",
	"highquality": 'جودة عالية',
	"normalquality": 'جودة عادية',
	"lowquality": 'جودة منخفضة',
	"playMode": "لعب",
	"continious":"مستمر",
	
	// Istikharah
	"ist_ceremonial": 'آداب الاستخارة',
	"ist_result": 'نتيجة',
	"ist_aya_display": 'عرض آية',
	"ist_istikharah": 'الاستخارة',
	"ist_repeat": 'كرر',
	"ist_page_display": 'عرض صفحة القرآن',
	"ist_result_display": 'عرض النتيجة',

	// Search
	"albalaq": 'Albalaq',
	"tafseer": 'التفسير',
	"in": 'في',
	"advancedSearch": 'المتقدمة',
	"allOfThem":'تحتوي على كل هذه الكلمات',
	"exacts":'بالضبط هذه العبارة',
	"oneOfThem":'واحدة على الأقل من هذه الكلمات',
	"noneOfThem":'بدون هذه الكلمات',
	"search":"بحث",



	//new
	"login":"Login",
	"phoneNumberNo0":"Phone Number whitout initial 0",
	"sendCode" : "send Code",
	"orLoginWith":"or Login With:",
	"verificationDescription": "we send you a code to verify your phone number",
	"wrongPhoneNumber?" : "Wrong Phone Number?",
	"newKhatam":"New Khatam",
	"titleOfKhatam":"Title Of Khatam",
	"titleOfKhatamCaption" : "Write a title for your Khatam",
	"selectKhatamSections":"Select the number of sections of your khatam:",
	"include":"Include",
	"about":"About",
	"pages":"الصفحات",
	"description":"description",
	"tags":"Tags",
	"tagsCaption":"Insert tag and press Enter",
	"create":"Create",
	"cancel":"Cancel",
	"doNotShowToPublic":"Do not show this Khatam to the public",
	"onlySignedUsers":"Only signed user allowed to join",
	"dontExtend":"ٍDon't Extend khatam automatically",
	"khatamCreatedMsg":"Khatam has created",
	"share":"Share",
	"join":"Join",
	"ofCourse":"of course",
	"shareKhatam":"Share this khatam",
	"jazakumullah":"جزاکم الله",
	"dedicatedToYou":"is dedicated to you",
	"to" : "To",
	"requestQuotas":"ًRequest Quotas",
	"readQuotas":"Read Quotas",
	"close":"Close",
	"contributers":"Contributers",
}

export default ar;