const en={
	"khatam":"Khatam",
	"sort":"sort",

	// App bar
	"forghan": "FORGHAN",
	"services": "Services",
	"language": "Language",
	"menu": "Menu",
	"closeMenu": "Close Menu",
	"searchAndFilter":"search And Filter",

	// Quran Menu
	"displayOption": "Display Options",
	"readingMode":"Reading Mode",
	"nightmode": "Night Mode",
	"daymode": "Day Mode",

	// Display Option
	"appearance": "Appearance",
	"text": "Text",
	"font": "Font",
	"lineHeight": "Line Height",
	"wordSpacing": "Word Spacing",
	"letterSpacing": "Letter Spacing",
	"alignment": "Alignment",
	"fontFamily": "Font Family",
	"fontSize": "Font Size",
	"fontColor": "Font Color",
	"textEdition":'Text Edition',
	"simpleDescription":"Simple (imla'ei) text",
	"simpleEnhancedDescription":'Simple text with ikhfa and idgham demonstration',
	"simpleMinimalDescription":'Simple text with a minimal number of diacritics and symbols',
	"simpleCleanDescription":"Simple text without any diacritics and symbols",
	"uthmaniDescription":"Uthmani text as in Medina Mushaf",
	"uthmaniMinimalDescription":'Uthmani text with a minimal number of diacritics and symbols',
	"tajwidDescription":'Text with capability of coloring tajwid rules',
	"submit": "Submit",
	"reset": "Reset",
	"tajweed": "Tajwid",
	"tajweedRules":"Tajwid Rules",
	"shade":"Shade",
	"hamzatUlWasl":"Hamzat ul Wasl",
	"silent":"Silent",
	"lamShamsiyah":"Lam Shamsiyah",
	"maddaNormal":'Madda Normal',
	"maddaPermissible":'Madda Permissible',
	"maddaNecessary":'Madda Necessary',
	"qalaqah":'Qalaqah',
	"maddaObligatory":'Madda Obligatory',
	"ikhafaShafawi":'Ikhafa Shafawi',
	"ikhafa":'Ikhafa',
	"idghamShafawi":'Idgham Shafawi',
	"iqlab":'Iqlab',
	"idghamWithGhunnah":'Idgham With Ghunnah',
	"idghamWithoutGhunnah":'Idgham Without Ghunnah',
	"idghamMutajanisayn":'Idgham Mutajanisayn',
	"idghamMutaqaribayn":'Idgham Mutaqaribayn',
	"ghunnah":'Ghunnah',

	// Drawer Menu
	"quran": "Quran",
	"browse": "Browse",
	"sura":"Sura",
	"aya":"Aya",
	"page":"Page",
	"juz": "Juz",
	"hizb": "Hizb",
	"rubElHizb":"Rub'el hizb",
	"ruku":"Ruku",
	"manzil": "Manzil",
	"translation":"Translation",
	"translator":"Translator",
	"addTranslation":"Add Translation",	
	"loop": "Loop",
	"noloop": 'No Loop',
	"recitation":"Recitation",
	"reciter":"Reciter",
	"type":"Type",
	"murattal":'Murattal',
	"mujawwad":'Mujawwad',
	"muallim":'Muallim',
	"quality":"Quality",
	"highquality": 'High Quality',
	"normalquality": 'Normal Quality',
	"lowquality": 'Low Quality',
	"playMode": "play",
	"continious":"Continious",
	
	// Istikharah
	"ist_ceremonial": 'Ceremonial Istikharah',
	"ist_result": 'Result',
	"ist_aya_display": 'Display Aya',
	"ist_istikharah": 'Istikharah',
	"ist_repeat": 'Repeat',
	"ist_page_display": 'Quran Page Display',
	"ist_result_display": 'Display Result',

	// Search
	"albalaq": 'Albalaq',
	"tafseer": 'Tafseer',
	"in": 'in',
	"advancedSearch": 'Advanced',
	"allOfThem":'Contain all of these words',
	"exacts":'Exactly this phrase',
	"oneOfThem":'At least one of these words',
	"noneOfThem":'Without these words',
	"search":"Search",

	//new
	"login":"Login",
	"phoneNumberNo0":"Phone Number whitout initial 0",
	"sendCode" : "send Code",
	"orLoginWith":"or Login With:",
	"verificationDescription": "we send you a code to verify your phone number",
	"wrongPhoneNumber?" : "Wrong Phone Number?",
	"newKhatam":"New Khatam",
	"titleOfKhatam":"Title Of Khatam",
	"titleOfKhatamCaption" : "Write a title for your Khatam",
	"selectKhatamSections":"Select the number of sections of your khatam:",
	"include":"Include",
	"about":"About",
	"pages":"pages",
	"description":"description",
	"tags":"Tags",
	"tagsCaption":"Insert tag and press Enter",
	"create":"Create",
	"cancel":"Cancel",
	"doNotShowToPublic":"Do not show this Khatam to the public",
	"onlySignedUsers":"Only signed user allowed to join",
	"dontExtend":"ٍDon't Extend khatam automatically",
	"khatamCreatedMsg":"Khatam has created",
	"share":"Share",
	"join":"Join",
	"ofCourse":"of course",
	"shareKhatam":"Share this khatam",
	"jazakumullah":"Jazakumullah",
	"dedicatedToYou":"is dedicated to you",
	"to" : "To",
	"requestQuotas":"ًRequest Quotas",
	"readQuotas":"Read Quotas",
	"close":"Close",
	"contributers":"Contributers",
}


export default en;