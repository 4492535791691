const langInfo={
    'en': {
        'lang':'en',
        'direction': 'ltr',
        'font': 'Roboto',
        displayName:'English'
    },
    'fa': {
        'lang':'fa',
        'direction': 'rtl',
        'font': 'vazir',
        displayName:'فارسی'
    },
    'ar': {
        'lang':'ar',
        'direction': 'rtl',
        'font': 'vazir',
        displayName:'عربی'
    },
    'ur': {
        'lang':'ur',
        'direction': 'rtl',
        'font': 'vazir',
        displayName:'اردو'
    }
}

export default langInfo;