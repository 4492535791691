/* eslint-disable no-console */
import React from 'react';
import {render} from 'react-dom';
import './styles/global.css';
import App from './App';

render(
	<App/>,
	document.getElementById('app')
); 

