import getInitialState from './initialState';
import configureStore from './configureStore';
import Dexie from 'dexie';

export const loadState = () => {

		let localData=undefined;
//load localStorage*****************************************************************************************
		let db = new Dexie("forghan.com");
		try{
			const serializedState = localStorage.getItem('forghan_com');
			if (serializedState === null) {
				localData = getInitialState();
			}else{
				localData = JSON.parse(serializedState);
			}
			
			//if there is no local data, no sura, no aya or no langInfo then load a defaul data
			if (!localData || !localData.userData.sura || !localData.userData.aya ||
					!localData.userData.langInfo || !localData.userData.theme ||
					!localData.quranData.edition || !localData.transData.selectedTrans[0].id ||
					!localData.quranData.quranTajwid || !localData.userData.login) {
				localData = getInitialState();
			};

			//temporal code due to changes in local storage in development
			//14000123
			if(localData.transData.selectedTrans[0].title_ln===undefined){console.log(localData.transData.selectedTrans[0].title_ln)
				localData.transData.selectedTrans[0]=getInitialState().transData.selectedTrans[0];
			}
			//14000216
			if(localData.userData.suggestLogin===undefined){
				localData.userData.suggestLogin=true;
			}

			//indexdb validation
			// let x=db.table('transText');
			// let y=db.table('quranText');

		} catch(err) {
			//if there is no user data, use default data
			console.error(err);
			db.delete();
			localData = getInitialState();
		}finally{
			let locale = require('../../locale/' + localData.userData.langInfo.lang + '.js');
			let transData={...localData.transData,transText:[],availableTrans:[]};
			let quranData={...localData.quranData,quranText:[]};
			let userData={...localData.userData,locale:locale.default};
			let reciteData={...localData.reciteData,reciteList:[]};
			
			// create a redux store with initial data
			const store = configureStore({transData,quranData,userData,reciteData});
			return store;
		}
}

export const saveState = (state) => {
	
	try{
		//save transText to indexDB******************************************
		// Create your instance
		let db = new Dexie("forghan.com");
		let transText=state.transData.transText;
		let qt=state.quranData.quranText;

		// Define your schema
		db.version(1).stores({
			transText : "++id,tid,vid",
			quranText: "++id,edition,vid"
		});

		// Open the database
		db.open().catch(function (e) {
			console.error("Open failed: " + e);
		});

		//add new translation to index db
		db.transaction("rw",db.transText,function(){
			transText.forEach((t,i)=>{
				//for each translation if not exist on db then add it otherwise do nothing
				db.transText.where('tid').equals(t.id).count().then((i)=>{
					if(i!==6236){
						t.text.forEach((tt,ii)=>{
							db.transText.add({tid:t.id,vid:ii+1,text:tt});
						})
					}else{}
				});
			});
		}).catch(function(error){
			console.error('localStorage->saveState():\n' + error);
		});

		//add new quran text edition to index db
		db.transaction("rw",db.quranText,function(){
			db.quranText.where('edition').equals(state.quranData.edition).count().then((i)=>{
				if(i!==6236){
					qt.forEach((v,i)=>{
						db.quranText.add({edition:state.quranData.edition,vid:i,text:v})
					})
				}
			});
		}).catch(function(error){
			console.error('localStorage>saveState():\n' + error);
		});

		//save rest of data in localStorage***********************************
		//transText has saved on IndexDB by Dexie
		//availableTrans does not need to save, it should be update by server each time
		let transData={...state.transData,transText:[],availableTrans:[]};
		let quranData={...state.quranData,quranText:[]};
		//there is not neccessary to store locale
		//always store suggestLogin as true because on next launch login should be suggest 
		let userData={...state.userData,locale:null,suggestLogin:true};
		let reciteData={...state.reciteData,reciteList:[]};
		let s={transData,quranData,userData,reciteData};
		const serializedState = JSON.stringify(s);
		localStorage.setItem('forghan_com', serializedState);
	} catch(err){
		console.error("localStorage.saveState():\n" + err);
	}
}