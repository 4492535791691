import React from 'react';
import { Switch,Route, Redirect} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import loadable from '@loadable/component'
import { CircularProgress} from '@material-ui/core';

// import Quran from '../components/Quran/Quran';
// import Istikharah from '../components/Istikharah/Istikharah';
// import Khatam from '../components/Khatam/MainKhatam';
// import KhatamPage from '../components/Khatam/KhatamPage';
// import PrivacyPolicy from '../components/privacy';

import {loadQuranText, loadTranslateText,getTranslationList,getRecitationList,
    getLangInfoByLocale, getCountryList} from './redux/actions/actionCreators';
import {loadState, saveState} from './redux/store/localStorage';
import {Provider} from 'react-redux';
import QuranMeta from './quranMeta/QuranMeta';
import {ErrorBoundary} from 'react-error-boundary';

const Quran=loadable(()=>import('./pages/Quran'),{fallback: waiting(),});
const Istikharah=loadable(()=>import('./components/Istikharah/Istikharah'),{fallback: waiting(),});
const Khatam=loadable(()=>import('./components/Khatam/MainKhatam'),{fallback: waiting(),});
const KhatamPage=loadable(()=>import('./components/Khatam/KhatamPage'),{fallback: waiting(),});
const PrivacyPolicy=loadable(()=>import('./components/privacy'),{fallback: waiting(),});

function waiting(){
  return <CircularProgress className="appLoadingProgress"/>;
}
class App extends React.Component {
  constructor(props){
    super(props);
    //load user data from their computer local storage and create redux store
    this.store=loadState();
    
    let storeState=this.store.getState();

    //find locale by ip if this is first time
    if(storeState.userData.firstTime){
      this.store.dispatch(getLangInfoByLocale());
    }
    
    //load quran text
    this.store.dispatch(loadQuranText(storeState.quranData.edition));

    //load transaltions
    storeState.transData.selectedTrans.forEach((translate)=>{
        this.store.dispatch(loadTranslateText(translate,storeState.transData.selectedTrans));
    });

    //load available translations from api
    this.store.dispatch(getTranslationList(storeState.transData.selectedTrans));

    //load recitations from server
    //the default recitation is determind inside getRecitationList
    this.store.dispatch(getRecitationList());

    //load list of countries for login
    this.store.dispatch(getCountryList());

    this.store.subscribe(() => {
      saveState(this.store.getState());
    });

    this.buildUrl=this.buildUrl.bind(this);

  }

  //if there is only one param in url (id or lang), we should determine which one is and
  //build compelete url (contain id and lang) and redirect to it
  buildUrl(param,routeType){
    let storeState=this.store.getState();
    if(routeType){//if there is a specific route type(e.g. sura,juz, ...)
      let sa;
      try{
        switch(routeType){
          case 'sura':
            sa={sura:param,aya:1};
            break;
          case 'aya':
              sa=QuranMeta.totalAyaToSA(param);
              break;
          case 'page':
              sa=QuranMeta.pageToSA(param);
              break;
          case 'juz':
              sa=QuranMeta.juzToSA(param);
              break;
          case 'hizb':
              sa=QuranMeta.hizbToSA(param);
              break;
            case 'manzil':
              sa=QuranMeta.manzilToSA(param);
              break;
            case 'rub':
              sa=QuranMeta.rubToSA(param);
              break;
          default :
            return '/';
        }
        return '/' + storeState.userData.langInfo.lang + '/' + sa.sura + ":" + sa.aya;
      }catch(er){
        return '/' + storeState.userData.langInfo.lang +  '/' +
            storeState.userData.sura + ':' + storeState.userData.aya;
      }
    }else{//if there is a one parameter url that may be lang or sura:aya or sura name
      if(param.indexOf(':') >= 0){//there is just id in url then use lang of localstorage
        return '/' + storeState.userData.langInfo.lang + '/' + param;
      }else{
        if(param.length===2){
          //there is just lang in url then use id in localstorage 
          //language validation will be done in Quran component
          return '/' + param + '/' + storeState.userData.sura + ':' + this.store.getState().userData.aya;
        }else{
          //it is sura name in url
          try{
            let sa=QuranMeta.suraNameToSA(param);
            return '/' + storeState.userData.langInfo.lang + '/' +sa.sura + ":" + sa.aya;
          }catch(er){
            return '/' + storeState.userData.langInfo.lang +  '/' +
                storeState.userData.sura + ':' + storeState.userData.aya;
          }
        }
      }
    }
  }

	render() {
    
    let pathname=window.location.pathname;
    let storeState=this.store.getState();
    
		return (
      <ErrorBoundary fallback={<h1>Something went wrong. Refresh The Page After 10 seconds!</h1>}
        onError={(e)=>{setTimeout(() => {
          console.log(e);
          localStorage.removeItem('forghan_com');
        }, 5000);}}

      >
        <Provider store={this.store}>
            <BrowserRouter>
              <Switch>
                {/* user insert root address then redirect to quran with saved sura,aya and lang */}
                <Route exact path="/" 
                  render={()=><Redirect to={"/" + storeState.userData.langInfo.lang + "/" +
                      storeState.userData.sura + ":" + storeState.userData.aya} /> }
                />

                {/* user use sura routing */}
                <Route path="/sura/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(6),'sura')} />}
                />

                {/* user use aya routing */}
                <Route path="/aya/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(5),'aya')} />}
                />

                <Route path="/page/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(6),'page')} />}
                />

                <Route path="/juz/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(5),'juz')} />}
                />

                <Route path="/hizb/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(6),'hizb')} />}
                />

                <Route path="/manzil/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(8),'manzil')} />}
                />

                <Route path="/rub/"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(5),'rub')} />}
                />

                <Route path="/privacy-policy" component={PrivacyPolicy}/>

                {/* user insert khatam page directory then redirect it to khatam page with saved lang */}
                <Route path="/khatam/:khatamId"
                  render={(props)=><Redirect to={"/"+storeState.userData.langInfo.lang+"/khatam/"+props.match.params.khatamId}/>}
                />

                {/* user insert lang for khatam page or system redirected it before to saved lang */}
                <Route path="/:lang/khatam/:khatamId" component={KhatamPage}/>

                {/* user insert khatam directory then redirect it to khatam with saved lang */}
                <Route path="/khatam"
                  render={()=><Redirect to={"/"+storeState.userData.langInfo.lang+"/khatam"}/>}
                />

                {/* user insert lang for khatam or system redirected it before to saved lang */}
                <Route path="/:lang/khatam" component={Khatam}/>

                {/* user insert istikharah directory then redirect it to istikharah with saved lang */}
                <Route path="/istikharah"
                  render={()=><Redirect to={"/"+storeState.userData.langInfo.lang+"/istikharah"}/>}
                />

                {/* user insert lang for istikharah or system redirected it before to saved lang */}
                <Route path="/:lang/istikharah" component={Istikharah}/>

                {/* user insert lang,sura,aya OR system redirected it before to saved sura,aya and lang */}
                <Route path="/:lang/:id" component={Quran} />
                
                {/* user insert just one param then recognize it*/}
                <Route path="/:langid"
                  render={()=><Redirect to={this.buildUrl(pathname.substring(1),null)}/>}
                />
              </Switch>
            </BrowserRouter>
        </Provider>
      </ErrorBoundary>
		);
	}
}
export default App;