function initialState(){
	return {
		'userData':{
			'langInfo' : {'lang' :'en','direction':'ltr','font':'Roboto',displayName:'English'},
			'sura' : 25,
			'aya' : 1,
			'theme':'light',
			firstTime:true,
			suggestLogin:true,
			login:{status:'off',verifTime:0,username:null,forcedOpen:false},//status values: off, on, waitForServer, waitForUser
			//log1in:{status:'on',verifTime:0,username:"Ehsan",forcedOpen:false},
			country:'',//only name from https://extreme-ip-lookup.com/json/,
			countryList:[]
		},
		'transData' : {
			'selectedTrans':[
				{
					"id": 1,
					"flag": "fa",
					"nickname_ln": "صادقی تهرانی",
					"name_ln": "محمد صادقی تهرانی",
					"title_ln": "ترجمان وحی",
					"language_ln":"فارسی",
					"language_en": "Persian",
					"Religion_en": "Shia",
					"type_en": "",
					"nationality_en": "Iranian",
					"nickname_en": "Sadeghi Tehrani",
					"name_en": "Muhammad Sadeghi Tehrani",                
					"bio_en": "",
					"yaer" : "2006",
					"recommend":"fa",
					"direction":"rtl",
					"image": "https://cdn.forghan.com/data/image/author/sadeghitehrani.png",
					"url": "https://cdn.forghan.com/data/text/translation/fa.sadeghi.txt"
				}
			],
			'transText':[],
			'availableTrans':[],
			'showTranslate' : true,
			'transDisplay' : {
				'fontFamily': 'vazir',
				'fontSize': '12px',
				'lineHeight': '30px',
				'textAlign': 'justify !important'
			},
		},
		'quranData':{
			edition:'si',
			'quranDisplay' : {
				'fontFamily': 'Quran Taha',
				'fontSize': '24px',
				'lineHeight': '54px',
				'wordSpacing' : '0px',
				'letterSpacing' : '0px',
				'color' : 'rgba(0, 0, 0, 0.87)',
				'textAlign': 'justify',
			},
			quranTajwid:{
				useTajwidColor:false,
				hamzatUlWasl:'#AAAAAA',
				silent:'#AAAAAA',
				lamShamsiyah:'#AAAAAA',
				maddaNormal:'#537FFF',
				maddaPermissible:'#4050FF',
				maddaNecessary:'#000EBC',
				qalaqah:'#DD0008',
				maddaObligatory:'#2144C1',
				ikhafaShafawi:'#D500B7',
				ikhafa:'#9400A8',
				idghamShafawi:'#58B800',
				iqlab:'#26BFFD',
				idghamWithGhunnah:'#169777',
				idghamWithoutGhunnah:'#169200',
				idghamMutajanisayn:'#A1A1A1',
				idghamMutaqaribayn:'#A1A1A1',
				ghunnah:'#FF7E1E',
			}
		},
		'reciteData':{
			'playerCnf' : {
				'loop' : 1,
				'PlaybackRate' : 1,
				'volume' : 0.8,
				'playMode': 8
			},
			'reciteList' : [],
			'selectedRecite':{
				'recitorId' : '',
				'reciteType' : '',
				'reciteQuality' : '',
			},
		}
	}
}

export default initialState;