const ur={

	//Global
	
	
	//Istikharah

	//Quran

	//khatam
	"khatam":"ختم",
	"sort":"sort",
	
	"quran": "قرآن کریم",

	"sura":"سورۃ",
	"translation":"ترجمہ",
	"translator":"مترجم",
	"addTranslation":"ترجمہ شامل کریں",
	"browse": "براؤز کریں",
	"searchAndFilter":"search And Filter",
	
	"readingMode":"پڑھنے کے موڈ",
	"nightmode": "رات کے موڈ",
	"daymode": "دن موڈ",
	"submit": "رجسٹریشن",
	"reset": "ری سیٹ",
	"services": "خدمات",
	"language": "زبان",
	"closeMenu": "بند کریں مینو",
	"menu": "مینو",
	"forghan": "فرقان",

	"page": "صفحہ",
	"aya": "آیت",
	"juz": "پارہ",
	"hizb": "حزب",
	"rubElHizb":"ربع حزب",
	"manzil": "منزیل",
	ruku:"Ruku",
	continious:"Continious",
        
	//Recitation
	type:"Type",
	"playMode": "کھیلنے کے",
	"loop": "لوپ",
	"noloop": 'کوئی لوپ',
	"recitation":"قرآت",
	"reciter":"قاری",
	quality:"Quality",
        
	// Istikharah
	'ist_ceremonial': 'رسمی استخاره',
	'ist_result': 'نتیجہ',
	'ist_aya_display': 'آیت مشاھدہ',
	'ist_istikharah': 'استخاره',
	'ist_repeat': 'دہرانے',
	'ist_page_display': 'قرآن صفحہ مشاھدہ',
	'ist_result_display': 'نتائج مشاھدہ',

	// Search
	albalaq: 'البلاغ',
	tafseer: 'تفسیر فرقان',
	in: 'در',
	advancedSearch: 'اعلی درجے کی تلاش',
	allOfThem:'Contain all of these words',
	exacts:'Exactly this phrase',
	oneOfThem:'At least one of these words',
	noneOfThem:'Without these words',
	"search":"تلاش",

	//options
	"displayOption": "نمائش",
	"appearance": "ظہور",
	"tajweed": "تجوید",
	"text": "متن",
	"font": "فونٹ",
	"lineHeight": "لکیر کی اونچائی",
	"wordSpacing": "لفظ انترالن",
	"letterSpacing": "خط انترالن",
	"alignment": "سیدھ",
	"fontFamily": "فونٹ خاندان",
	"fontSize": "حرف کا سائز",
	"fontColor": "لکھائی کا رنگ",
	tajweedRules:"Tajwid Rules",
	shade:"Shade",
	hamzatUlWasl:"Hamzat ul Wasl",
	silent:"Silent",
	lamShamsiyah:"Lam Shamsiyah",
	maddaNormal:'Madda Normal',
	maddaPermissible:'Madda Permissible',
	maddaNecessary:'Madda Necessary',
	qalaqah:'Qalaqah',
	maddaObligatory:'Madda Obligatory',
	ikhafaShafawi:'Ikhafa Shafawi',
	ikhafa:'Ikhafa',
	idghamShafawi:'Idgham Shafawi',
	iqlab:'Iqlab',
	idghamWithGhunnah:'Idgham With Ghunnah',
	idghamWithoutGhunnah:'Idgham Without Ghunnah',
	idghamMutajanisayn:'Idgham Mutajanisayn',
	idghamMutaqaribayn:'Idgham Mutaqaribayn',
	ghunnah:'Ghunnah',
	textEdition:'متن کی قسم',
	simpleDescription:"Simple (imla'ei) text",
	simpleEnhancedDescription:'Simple text with ikhfa and idgham demonstration',
	simpleMinimalDescription:'Simple text with a minimal number of diacritics and symbols',
	simpleCleanDescription:"Simple text without any diacritics and symbols",
	uthmaniDescription:"Uthmani text as in Medina Mushaf",
	uthmaniMinimalDescription:'Uthmani text with a minimal number of diacritics and symbols',
	tajwidDescription:'Text with capability of coloring tajwid rules',

	//new
	"login":"Login",
	"phoneNumberNo0":"Phone Number whitout initial 0",
	"sendCode" : "send Code",
	"orLoginWith":"or Login With:",
	"verificationDescription": "we send you a code to verify your phone number",
	"wrongPhoneNumber?" : "Wrong Phone Number?",
	"newKhatam":"New Khatam",
	"titleOfKhatam":"Title Of Khatam",
	"titleOfKhatamCaption" : "Write a title for your Khatam",
	"selectKhatamSections":"Select the number of sections of your khatam:",
	"include":"Include",
	"about":"About",
	"pages":"pages",
	"description":"description",
	"tags":"Tags",
	"tagsCaption":"Insert tag and press Enter",
	"create":"Create",
	"cancel":"Cancel",
	"doNotShowToPublic":"Do not show this Khatam to the public",
	"onlySignedUsers":"Only signed user allowed to join",
	"dontExtend":"ٍDon't Extend khatam automatically",
	"khatamCreatedMsg":"Khatam has created",
	"share":"Share",
	"join":"Join",
	"ofCourse":"of course",
	"shareKhatam":"Share this khatam",
	"jazakumullah":"Jazakumullah",
	"dedicatedToYou":"is dedicated to you",
	"to" : "To",
	"requestQuotas":"ًRequest Quotas",
	"readQuotas":"Read Quotas",
	"close":"Close",
	"contributers":"Contributers",
}

export default ur;